import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EternalPlus from "../../components/Portfolio/EternalPlus"

const EternalPlusPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <EternalPlus />
  </Layout>
)

export default EternalPlusPage
